// registerServiceWorker.js
import { register } from "register-service-worker";

register(`${process.env.BASE_URL}service-worker.js`, {
  ready(registration) {
    console.log(
      "App is being served from cache by a service worker.\n",
      registration
    );
  },
  registered(registration) {
    console.log("Service worker has been registered.\n", registration);
  },
  cached(registration) {
    console.log("Content has been cached for offline use.\n", registration);
  },
  updatefound(registration) {
    console.log("New content is downloading.\n", registration);
  },
  updated(registration) {
    console.log("New content is available; please refresh.\n", registration);
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
});
