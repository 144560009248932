import Vue from "vue";
import VueRouter from "vue-router";
import App from "../views";
import AuthRequired from "../utils/AuthRequired";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "App",
    component: App,
    beforeEnter: AuthRequired,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("../views/HomeV2.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
      },
      {
        path: "transaksi",
        name: "Transaksi",
        component: () => import("../views/Transaksi"),
        redirect: "/transaksi/dashboard",
        children: [
          {
            path: "dashboard/:no_anggota?",
            name: "Dashboard",
            component: () => import("../views/Transaksi/Dashboard.vue"),
          },
          {
            path: "setoran",
            name: "Setoran",
            component: () => import("../views/Transaksi/Setoran.vue"),
          },
        ],
      },
      {
        path: "angsuran",
        name: "Angsuran",
        component: () => import("../views/Angsuran"),
        redirect: "/angsuran/kumpulan",
        children: [
          {
            path: "kumpulan",
            name: "AngsuranKumpulan",
            component: () => import("../views/Angsuran/AngsuranKumpulan.vue"),
          },
          {
            path: "kelompok/:cm_code?/:branch_id?/:tanggal?",
            name: "AngsuranKelompok",
            component: () => import("../views/Angsuran/AngsuranKelompok.vue"),
          },
          {
            path: "anggota/:cm_code?/:branch_id?/:kode_kelompok?/:transaction_date?",
            name: "AngsuranAnggota",
            component: () => import("../views/Angsuran/AngsuranAnggota.vue"),
          },
          {
            path: "infaq/:cm_code?/:branch_id?/:transaction_date?/:infaq?",
            name: "InfaqAnggota",
            component: () => import("../views/Angsuran/InfaqAnggota.vue"),
          },
        ],
      },
      {
        path: "keanggotaan",
        name: "Keanggotaan",
        component: () => import("../views/Keanggotaan"),
        redirect: "/keanggotaan/dashboard",
        children: [
          {
            path: "dashboard",
            name: "KeanggotaanDashboard",
            component: () => import("../views/Keanggotaan/Dashboard.vue"),
          },
          {
            path: "registrasiMasuk",
            name: "KeanggotaanRegistrasiMasuk",
            component: () =>
              import("../views/Keanggotaan/RegistrasiMasuk/index.vue"),
          },
          {
            path: "registrasiKeluar",
            name: "KeanggotaanRegistrasiKeluar",
            component: () =>
              import("../views/Keanggotaan/RegistrasiKeluar/index.vue"),
          },
        ],
      },
      {
        path: "pembiayaan",
        name: "Pembiayaan",
        component: () => import("../views/Pembiayaan"),
        redirect: "/pembiayaan/dashboard",
        children: [
          {
            path: "dashboard",
            name: "PembiayaanDashboard",
            component: () => import("../views/Pembiayaan/Dashboard.vue"),
          },
          {
            path: "pencairan",
            name: "PembiayaanPencairan",
            component: () => import("../views/Pembiayaan/Pencairan.vue"),
          },
          {
            path: "pengajuan",
            name: "PembiayaanPengajuan",
            component: () => import("../views/Pembiayaan/Pengajuan.vue"),
          },
        ],
      },
      {
        path: "tabungan",
        name: "Tabungan",
        component: () => import("../views/Tabungan"),
        redirect: "/tabungan/dashboard",
        children: [
          {
            path: "setor_tarik",
            name: "TabunganSetorTarik",
            component: () => import("../views/Tabungan/SetorTarik.vue"),
          },
          {
            path: "kelompok/:cm_code?/:branch_id?/:tanggal?",
            name: "TabunganKelompok",
            component: () => import("../views/Tabungan/TabunganKelompok.vue"),
          },
          {
            path: "anggota/:cm_code?/:branch_id?/:kode_kelompok?/:transaction_date?",
            name: "TabunganAnggota",
            component: () => import("../views/Tabungan/TabunganAnggota.vue"),
          },
          {
            path: "dashboard",
            name: "TabunganDashboard",
            component: () => import("../views/Tabungan/Dashboard.vue"),
          },
          {
            path: "pencairan",
            name: "TabunganPencairan",
            component: () => import("../views/Tabungan/Pencairan.vue"),
          },
          {
            path: "registrasi",
            name: "TabunganRegistrasi",
            component: () => import("../views/Tabungan/Registrasi.vue"),
          },
        ],
      },
      {
        path: "others",
        name: "others",
        component: () => import("../views/Others"),
        redirect: "/others/dashboard",
        children: [
          {
            path: "dashboard",
            name: "othersDashboard",
            component: () => import("../views/Others/Dashboard.vue"),
          },
          {
            path: "ppob",
            name: "othersPencairan",
            component: () => import("../views/Others/PPOB.vue"),
          },
          {
            path: "wakaf",
            name: "othersRegistrasi",
            component: () => import("../views/Others/Wakaf/index.vue"),
            redirect: "/others/wakaf/kumpulan",
            children: [
              {
                path: "kumpulan",
                name: "WakafKumpulan",
                component: () =>
                  import("../views/Others/Wakaf/WakafKumpulan.vue"),
              },
              {
                path: "kelompok/:cm_code?/:branch_id?/:tanggal?",
                name: "WakafKelompok",
                component: () =>
                  import("../views/Others/Wakaf/WakafKelompok.vue"),
              },
              {
                path: "anggota/:cm_code?/:branch_id?/:kode_kelompok?/:transaction_date?",
                name: "WakafAnggota",
                component: () =>
                  import("../views/Others/Wakaf/WakafAnggota.vue"),
              },
            ],
          },
        ],
      }
    ],
  },
  {
    path: "/send",
    name: "Send",
    component: () => import("../views/Send.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("../views/Forgot.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(`From ${from.fullPath} to ${to.fullPath}`);
  next();
});

export default router;
