<template>
  <div class="purple lighten-1 px-5 py-3 justify-center align-items-center bt-navigation"
    :class="showMenu ? 'd-flex' : 'd-none'">
    <router-link v-for="item in items" :key="item.title" :to="item.path"
      class="d-flex justify-center align-center rounded-lg pa-1 mx-4"
      :class="($route.path == item.path) ? 'purple darken-1' : 'purple lighten-5 elevation-3'">
      <v-icon :color="($route.path == item.path) ? 'purple lighten-5' : 'purple darken-1'">
        {{ item.icon }}
      </v-icon>
    </router-link>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      { title: 'Home', path: '/home', icon: 'mdi-home' },
      { title: 'Keanggotaan', path: '/keanggotaan', icon: 'mdi-account-group' },
      { title: 'Pembiayaan', path: '/pembiayaan', icon: 'mdi-cash-multiple' },
      { title: 'Tabungan', path: '/tabungan', icon: 'mdi-wallet' },
      { title: 'Lainnya', path: '/others', icon: 'mdi-more' },
      { title: 'Kirim', path: '/send', icon: 'mdi-send' }
    ]
  }),
  computed: {
    showMenu() {
      return !['/login', '/forgot'].includes(this.$route.path)
    }
  }
}
</script>
